<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-13 14:29:54
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-20 10:00:13
-->
<template>
  <div class="control">
    <div class="w ww my_index">
      <div class="pr">
        <div class="my_order_rt">
          <div class="my_order_fp">
            <ul>
              <li v-for="v in invoiceList" :key="v.owid">
                <h3>{{this.$t('duty')}}：{{v.taxCode}}<span>￥{{v.billAmount}}</span><a @click="deleteBill(v.owid)"><img src="@/assets/img/81.png" /></a></h3>
                <p><span>{{this.$t('corporateName')}}：{{v.billContent}}</span><span>{{this.$t('BillingTime')}}：{{v.lastupdate}}</span></p>
                <dl v-for="x in v.productList" :key="x.orderRefOwid">
                  <dd><a>{{this.$t('AssociatedOrder')}}：<img :src="pictrueUrl + x.productPic" />{{x.productName}}</a></dd>
                  <dd>{{this.$t('unitPrice')}}：￥{{x.itemPrice}}</dd>
                  <dd>{{this.$t('quantity')}}：{{x.itemCount}}</dd>
                  <dd>{{this.$t('orderTime')}}：{{x.orderCreatetime}}</dd>
                  <div class="clear"></div>
                </dl>
              </li>
              <li> <NullTip :tip="this.$t('NoInvoiceInfo')" v-if="invoiceList === undefined"></NullTip></li>
            </ul>
          </div>
          
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Search from '@/components/component/Search.vue'
import { getBillList,deleteBill } from './model'
import { IMGURL } from "@/utils/constant";
import { showLayer } from "@/utils/com";
import NullTip from "@/components/component/NullTip.vue";
export default {
  data(){
    return {
      controlParams:{
        pageNo:1,
        pageSize:3
      },
      invoiceList:'',
      pictrueUrl:''
    }
  },
  components: {
    Search,
    NullTip
  },
  created(){
    this.pictrueUrl = IMGURL;
    this.getBillList()
    
  },
  methods:{
    deleteBill(owid){
      deleteBill({owid:owid}).then(res =>{
        if(res === '删除成功'){
          showLayer("toast", "删除成功！");
         this.getBillList()
        }
      })
    },
    getBillList(){
      getBillList(this.controlParams).then(res=>{
      this.invoiceList =res.records;
      console.log(this.invoiceList);
    })
    }
  }

}
</script>

<style>
</style>